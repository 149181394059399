import { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import Image from "next/image";
import { createPortal } from "react-dom";

import Button from "@musicfy/components/Button";
import Icon from "@musicfy/components/Icon";
import { PublicBuckets } from "@musicfy/utils/hooks/useStorage";

import { EFeatures, FEATURE_ROUTES } from "../Navigation/constants";

type TSlide = {
  title: string;
  subtitle: string;
  isNew?: boolean;
  imageSrc: string;
  objectPosition?: string;
  path?: string;
  buttonText?: string;
};

const SLIDES: TSlide[] = [
  {
    title: "Pro Tools",
    subtitle:
      "Seperate any audio into stems and explore endless possibilities with Musicfy Pro Tools",
    isNew: true,
    imageSrc: `${PublicBuckets.IMAGES}dashboard/text_to_music_banner.png`,
    objectPosition: "50% 25%",
    path: `${FEATURE_ROUTES[EFeatures.STEM_SPLITTER]}`,
    buttonText: "Try Now",
  },
  {
    title: "Change Your Voice",
    subtitle:
      "Use AI to create music with your voice or other voices and make music like never before",
    isNew: false,
    imageSrc: `${PublicBuckets.IMAGES}dashboard/create_voice_banner.png`,
    objectPosition: "70% 50%",
    path: `${FEATURE_ROUTES[EFeatures.VOICE]}`,
    buttonText: "Create Now",
  },

  {
    title: "Community Models",
    subtitle: "Explore and use models created by the Musicfy community",
    isNew: true,
    imageSrc: `${PublicBuckets.IMAGES}dashboard/pro_tools_banner.png`,
    objectPosition: "70% 50%",
    path: `${
      FEATURE_ROUTES[EFeatures.VOICE]
    }?open_takeover=true&models_tab=Community`,
    buttonText: "Explore",
  },
  {
    title: "Train Your Own Voice",
    subtitle:
      "Train your own voice and create music to explore endless possibilities",
    isNew: false,
    imageSrc: `${PublicBuckets.IMAGES}dashboard/train_voice_banner.png`,
    path: `${FEATURE_ROUTES[EFeatures.VOICES]}`,
    buttonText: "Train Now",
  },
];

const Carousel = (): JSX.Element | null => {
  const [selectedIndex, setSelectedIndex] = useState(1);

  const interval = useRef<NodeJS.Timeout>();
  const carouselContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /* Do this to avoid glitchy behavior when switching layouts */
    setSelectedIndex(0);
  }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      const _newIndex =
        selectedIndex + 1 >= SLIDES.length ? 0 : selectedIndex + 1;

      setSelectedIndex(_newIndex);
    }, 15000);

    return () => {
      clearInterval(interval.current);
    };
  }, [selectedIndex]);

  useEffect(() => {
    const verticalDistance =
      selectedIndex * (carouselContainer.current?.clientHeight || 0);
    const horizontalDistance =
      selectedIndex * (carouselContainer.current?.clientWidth || 0);

    carouselContainer.current?.scrollTo({
      top: verticalDistance,
      left: horizontalDistance,
      behavior: "smooth",
    });
  }, [selectedIndex]);

  const activeSlide = SLIDES[selectedIndex];

  if (!activeSlide) {
    return null;
  }

  return (
    <>
      {createPortal(
        <div
          ref={carouselContainer}
          className="w-full h-[60vh] lg:h-[70vh] min-h-[400px] max-h-[600px] flex lg:flex-col flex-row flex-nowrap overflow-hidden"
        >
          <div className="flex w-fit h-fit absolute lg:top-1/2 lg:-translate-y-1/2 lg:left-6 bottom-20 right-6 lg:flex-col flex-row items-center gap-3 z-30">
            {SLIDES.map((slide, index) => {
              const isActive = index === selectedIndex;

              return (
                <div
                  aria-label={`Go to slide ${index + 1}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                  key={index}
                  className={clsx("rounded-full transition-all duration-500", {
                    "bg-white w-[10px] h-[10px]": isActive,
                    "bg-violet w-2 h-2": !isActive,
                  })}
                />
              );
            })}
          </div>
          {SLIDES.map((slide, index) => {
            const isActiveIndex = selectedIndex === index;

            const {
              title,
              subtitle,
              isNew,
              imageSrc,
              objectPosition,
              buttonText,
              path,
            } = slide;

            return (
              <div
                key={title}
                className={clsx(
                  "pl-6 transition-all duration-500 lg:pl-24 flex-0-auto flex w-full h-full after:contents-[''] after:w-full after:h-1/3 after:absolute after:left-0 after:bottom-0 after:bg-gradient-to-b after:from-gray-1000/0 after:to-gray-1000 after:z-0",
                  {
                    "opacity-full": isActiveIndex,
                    "opacity-0": !isActiveIndex,
                  }
                )}
              >
                <Image
                  src={imageSrc}
                  alt="banner"
                  style={{
                    objectPosition: objectPosition || "50% 50%",
                  }}
                  unoptimized
                  width={1000}
                  height={1000}
                  className="absolute w-full h-full transition-all duration-700 object-cover right-0 bottom-0"
                />
                <div className="lg:relative flex items-center gap-16">
                  <div className="!text-left z-30 relative lg:max-w-full max-w-[60%]">
                    {isNew && <Icon name="new" className="w-14 lg:w-24" />}
                    <div className="text-3xl font-bold leading-snug text-32 lg:text-56 mt-4">
                      {title}
                    </div>
                    <div className="text-14 mt-3 tracking-wide pl-[2px] leading-relaxed">
                      {subtitle}
                    </div>
                    {!!buttonText && !!path && (
                      <div className="lg:mt-8 mt-6">
                        <Button className="!px-12" path={path}>
                          {buttonText}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>,
        document.getElementById("inner-window-container") || document.body,
        "dashboard-banners"
      )}
    </>
  );
};

export default Carousel;
